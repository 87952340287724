.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #FEDC55; 
    /* Ajustar el 0.7 en rgba para mas transparencia, 1=opaco*/
    padding: 10px 10%;
    /* Cambiado de 0px a 10px para darle algo de padding */
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
}

.logo {
    font-size: 25px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
}

.navigation ul {
    list-style: none;
}

.header .navigation ul li {
    float: left;
    position: relative;
}

.header .navigation ul li a {
    font-size: 18px;
    color: #11131d;
    text-decoration: none;
    padding: 20px;
    display: block;
    transition: all .2s ease;
}

.header .navigation ul li a:hover {
    background-color: #11131d;
    color: #ffffff;

        /* Cambiar la transparencia ajustando el valor de alfa */
    }

.header .navigation ul li ul {
    position: absolute;
    right: 0;
    width: 300px;
    background-color: rgba(1, 1, 1, 0.5);
    display: none;
}

.header .navigation ul li ul li a {
    font-size: 15px;
    text-transform: capitalize;
}

.header .navigation ul li ul li ul {
    position: absolute;
    top: 0;
    right: 300px;
}

.header .navigation ul li ul li {
    width: 100%;
}

.header .navigation ul li:hover>ul {
    display: initial;
}

#toggle,
.header label {
    display: none;
    cursor: pointer;
}
.menu {
    width: 45px;
    height: 35px;
}
.logo {
    height: "50px";
}
.body {
    margin-top: 98px;
}
.about {
    text-align: center;
    padding: 40px
}
.about-description {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    line-height: 25px;
}
.about-description img { 
    max-height: 400px;
}
.about-description .about-long-desc {
    margin-left: 30px;
}

@media (max-width: 950px) {
    .header {
        padding: 10px 10%;
        /* Asegura que el padding sea consistente en dispositivos móviles */
    }

    .header label {
        display: initial;
    }

    .header .navigation {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: #FEDC55;
        display: none;
    }

    .header .navigation ul {
        padding: 0;
    }

    .header .navigation ul li {
        width: 100%;
        float: none;
        /* Asegura que los elementos se apilen verticalmente en móviles */
    }

    .header .navigation ul li a {
        padding: 8px 30px 8px 10%;
    }

    .header .navigation ul li ul {
        position: relative;
        width: 100%;
        left: 0;
    }

    .header .navigation ul li ul {
        background-color: rgba(1, 1, 1, 0.7);
    }

    .header .navigation ul li ul li ul {
        position: relative;
        width: 100%;
        left: 0;
    }

    #toggle:checked~.navigation {
        display: block;
    }
}

.carousel-container {
    margin: auto;
    height: 500px;
}
.carousel-container img {
    max-height: 500px;
    margin: auto;
    width: 100%;
}
.about-container {
    margin: auto;
    text-align: center;
    background-color: #fff;
    padding: 30px
}
.product-container {
    padding: 30px;
}
.contact-container {
    background-color: #fff;
    padding: 30px;
}
.about-flex-container p {
    font-size: 24px;
}
.about-flex-container {
    display: flex;
    padding: 20px;
    width: 70%;
    text-align: left;
    margin: auto;
}
.about-header {
    width: 50%;
    margin: auto;
    padding: 30px;
    line-height: 1.3;
}
.about-welcome {
    color: green;
}
.about-title {
    font-size: 40px;
    color: #c8a70f;
    padding: 0
}
.about-flex-container .about-col {
    padding: 0px 30px 0 30px;
    line-height: 32px;
}
.about-flex-container .about-col img {
    width: 400px;
}
.product-galary-container {
    margin-top: 10px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.contact-section {
    text-align: center;
    background-color: #fff;
    padding: 30px;
}

/* ************************************************************************ */
/* Google Font CDN Link */

.container {
  max-width: 1100px;
  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin: 20px auto;
}
.container .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container .content .left-side {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}
.content .left-side::before {
  content: "";
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #afafb6;
}
.content .left-side .details {
  margin: 14px;
  text-align: center;
}
.content .left-side .details i {
  font-size: 30px;
  color: #3e2093;
  margin-bottom: 10px;
}
.content .left-side .details .topic {
  font-size: 18px;
  font-weight: 500;
}
.content .left-side .details .text-one,
.content .left-side .details .text-two {
  font-size: 14px;
  color: #afafb6;
}

.container .content .right-side {
  width: 75%;
  margin-left: 75px;
}
.content .right-side .topic-text {
  font-size: 23px;
  font-weight: 600;
  color: #3e2093;
}
.right-side .input-box {
  height: 55px;
  width: 100%;
  margin: 12px 0;
}
.right-side .input-box input,
.right-side .input-box textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #f0f1f8;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}
.right-side .message-box {
  min-height: 110px;
}
.right-side .input-box textarea {
  padding-top: 6px;
}
.right-side .button {
  display: inline-block;
  margin-top: 12px;
}
.right-side .button input[type="submit"] {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #3e2093;
  cursor: pointer;
  transition: all 0.3s ease;
}
.button input[type="submit"]:hover {
  background: #5029bc;
}

@media (max-width: 950px) {
  .container {
    width: 90%;
    padding: 30px 40px 40px 35px;
  }
  .container .content .right-side {
    width: 75%;
    margin-left: 55px;
  }
}
@media (max-width: 820px) {
  .container {
    margin: 40px 0;
    height: 100%;
  }
  .container .content {
    flex-direction: column-reverse;
  }
  .container .content .left-side {
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .container .content .left-side::before {
    display: none;
  }
  .container .content .right-side {
    width: 100%;
    margin-left: 0;
  }
}
.footer-container {
    background-color: #11131d;
    height: 100px;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}
.footer-container p {
    margin: 40px auto;
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #FEDC55; 
    /* Ajustar el 0.7 en rgba para mas transparencia, 1=opaco*/
    padding: 10px 10%;
    /* Cambiado de 0px a 10px para darle algo de padding */
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
}

@media only screen and (max-width: 600px) {
 .carousel-container {
  height: fit-content;
 }
 .about-header {
  width: 100%;
  padding: 0
 }
 .about-flex-container {
  display: block;
  width: 100%;
 }
 .about-flex-container .about-col {
  padding: 0;
 }
 .ant-card {
  width: 100%;
 }
}