body {
    font-family: "Poppins", sans-serif;
    min-height: 100vh;
    box-sizing: border-box;
    font-weight: 400;
    font-style: normal;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #11131d;
    background-color: #E3E6E3;
}
